import React, { useEffect, useState } from 'react'
import Layout from '../components/layouts'
import Container from '../components/Container'
import HeroImage from '../images/heroimg.png'
import FeatureSection from '../components/FeatureSection'
import TabletImage from '../images/ipad.webp'
import NotificationsImage from '../images/notifications.webp'
import CalendarImage from '../images/calendar.webp'
import Carousel from '../components/Carousel'
import { Helmet } from 'react-helmet'

import DiscordImage from '../images/icons/classify discord.webp'
import TwitterImage from '../images/icons/classify twitter.webp'
import InstagramImage from '../images/icons/classify instagram.webp'
import YoutubeImage from '../images/icons/classify youtube.webp'

import CloseIcon from '../images/icons/close-icon.webp'

import AlertConfig from '../ALERTBAR_CONFIG'
import Subscribe from '../components/Subscribe'

import StoreEmbedButtons from '../components/StoreEmbedButtons'
import { graphql } from 'gatsby'

import PartnerCard from '../components/PartnerCard'
import TestimonialCarousel from '../components/TestimonialCarousel'
import RightCallArrow from '../images/right_c2a_arrow.png'
import LeftCallArrow from '../images/left_c2a_arrow.png'
import ReviewCards from '../components/ReviewCards'
import CalendarIcon from '../images/calendar_outline.png'
import NotificationsIcon from '../images/notifications_outline.png'
import TabletIcon from '../images/tablet_support_outline.png'
import HeroImageMobile from '../images/heroimg_mobile.png'
import ButtonLink from '../components/ButtonLink'

const IndexPage = ({ data }) => {
	const [modalClosed, setModalClosed] = useState(false)

	useEffect(() => {
		setModalClosed(localStorage.getItem('modal-dismissed'))
	}, [])

	useEffect(() => {
		localStorage.setItem('modal-dismissed', modalClosed)
	}, [modalClosed])

	// const [modalClosed, setModal] = useState(typeof localStorage !== 'undefined' ? localStorage.getItem('modal-closed') !== 'true' : false)
	// useEffect(() => {
	//   localStorage.setItem('modal-closed', modalClosed)
	// }, [modalClosed])

	const moreFeatures = [
		{
			title: 'Tablet support',
			icon: TabletIcon,
			image: TabletImage,
		},
		{
			title: 'Calendar',
			icon: CalendarIcon,
			image: CalendarImage,
		},
		{
			title: 'Notifications',
			icon: NotificationsIcon,
			image: NotificationsImage,
		},
	]

	const icons = [
		{
			name: 'Discord',
			url: 'https://discord.gg/EYSZ5QEEYC',
			image: DiscordImage,
		},
		{
			name: 'Twitter',
			url: 'https://twitter.com/classifyapp',
			image: TwitterImage,
		},
		{
			name: 'Instagram',
			url: 'https://www.instagram.com/classifyapp',
			image: InstagramImage,
		},
		{
			name: 'Youtube',
			url: 'https://www.youtube.com/channel/UC0gSikZD5N-Uoej3lPuknxw',
			image: YoutubeImage,
		},
	]

	return (
		<>
			<Helmet>
				<title>Classify — School Planner</title>
				<meta
					name="description"
					content="The all-in-one cross platform app for students to get organized, easily. Start now to get your academic life in order - minimal setup required."
				/>
				<link rel="canonical" href="https://classify.org.uk/" />

				<meta property="og:title" content="Classify — School Planner" />
				<meta property="og:url" content="https://classify.org.uk/" />
				<meta
					property="og:image"
					content="https://i.imgur.com/ODTJzzm.png"
				/>
				<meta
					property="og:description"
					content="The all-in-one cross platform app for students to get organized, easily. Start now to get your academic life in order - minimal setup required."
				/>
				<meta property="og:type" content="website" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@classifyapp" />
				<meta
					property="twitter:title"
					content="Classify — School Planner"
				/>
				<meta
					name="twitter:image"
					content="https://i.imgur.com/ODTJzzm.png"
				/>
				<meta
					property="twitter:description"
					content="The all-in-one cross platform app for students to get organized, easily. Start now to get your academic life in order - minimal setup required."
				/>
			</Helmet>
			<div
				className="fixed left-6 hidden lg:flex flex-col z-10 mt-6"
				style={{ top: AlertConfig.enabled ? '100px' : '60px' }}
			>
				{icons.map(({ name, url, image }, index) => (
					<a
						href={url}
						target="_blank"
						rel="noopener noreferrer"
						className="mb-4"
						key={index}
					>
						<img
							src={image}
							alt={name}
							width="16"
							height="16"
							className="w-6 h-auto"
						/>
					</a>
				))}
			</div>
			{!modalClosed && (
				<div className="hidden lg:block fixed left-12 bottom-12 z-10 drop-shadow-xl bg-white rounded-xl">
					<div className="absolute top-2 right-2">
						<img
							src={CloseIcon}
							alt="Close"
							className="w-6 h-auto bg-cover cursor-pointer"
							onClick={() => setModalClosed(true)}
						/>
					</div>
					<div className="flex flex-col p-6">
						<span className="text-black text-4xl pb-2 font-college">
							THE WEEKLY TAB
						</span>
						<p className="text-black w-96 pb-4">
							Stories, guides and ideas written to broaden
							perspectives and provide helpful insights. 🔥
						</p>
						<Subscribe />
					</div>
				</div>
			)}
			<Layout>
				<main>
					<section id="hero" className="bg-hero overflow-x-hidden">
						<div
							className="mx-auto relative  px-0"
							style={{ maxWidth: '1536px' }}
						>
							<div className="mt-30 pt-5 md:pt-12 lg:pt-36 flex justify-center">
								<div className="flex flex-col lg:flex-row relative md:max-w-[60rem]">
									<div className="text-center lg:text-left w-full lg:w-2/5 lg:pb-80 px-4 py-4 z-[2]">
										<div className="md:mb-8">
											<span
												className="inline-block font-tinyAngels text-4xl sm:text-5xl font-black whitespace-nowrap rotate-[356deg] pb-2"
												style={{ lineHeight: 1.25 }}
											>
												PLAN EASY, <br /> T0GETHER
											</span>
										</div>
										<div className="pt-4 lg:pt-0 lg:pl-10">
											<div className="mb-8 hero-subtitle text-xl">
												Let classmates share and discuss
												homework, events and clubs with
												you easily. Organisation, made
												social. Make your school life
												easier in just 2 minutes.
											</div>
											<StoreEmbedButtons className="store-embed-main" />
										</div>
									</div>
									<div
										className="lg:hidden w-full h-0 bg-top hero-img"
										style={{
											backgroundImage: `url(${HeroImageMobile})`,
											paddingTop: '105%',
											backgroundSize: 'cover',
											transform:
												'rotate(-0deg) scale(0.9)',
										}}
									/>
									<div className="hero-img hidden lg:block absolute w-full h-auto right-[-10rem] top-[-10rem]">
										<img
											src={HeroImage}
											alt="Hero image"
											width="128"
											height="128"
											className="w-full h-auto hero-img"
											style={{
												transform:
													'rotate(-15deg) scale(0.8)',
											}}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="mt-[-2rem] xl:mt-15 overflow-hidden">
							<Container
								no-vertical
								className="text-center pb-5 md:pb-20"
							>
								<span className="text-white lg:text-2xl text-lg">
									Powering over 100k students worldwide.
									<br />
									From high school hopefuls to university
									graduates.
								</span>
							</Container>
						</div>
						<section
							id="testimonials"
							className="hidden lg:block pb-10"
						>
							<TestimonialCarousel />
						</section>
					</section>
					<section id="features" className="mt-12 lg:mt-20 mb-7">
						<div className="text-center">
							<span className="inline-block font-tinyAngels text-[1.7rem] sm:text-4xl md:text-5xl font-black break-words">
								A PLANNER THAT{' '}
								<span className="custom-underline">
									PUTS Y0U AHEAD.
								</span>
							</span>
						</div>
						<FeatureSection />
					</section>

					<section id="more-features" className="carousel-bg">
						<div className="py-[3rem]">
							<div className="flex flex-col items-center">
								<div className="mb-8">
									<span className="inline-block font-tinyAngels text-4xl sm:text-5xl md:text-6xl font-black whitespace-nowrap">
										AND M0RE...
									</span>
								</div>
								<Carousel data={moreFeatures} />
							</div>
						</div>
					</section>
					<section id="get-classify" className="bg-c2a">
						<Container no-vertical>
							<article className="text-center hidden lg:block">
								<h3 className="py-12 inline-block text-2xl sm:text-3xl md:text-4xl font-black">
									What students are saying about Classify
								</h3>
								<div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-12">
									{data.allWpPartnersTestimonial.nodes.map(
										(partner, index) => (
											<PartnerCard
												name={partner.title}
												imgAlt={
													partner
														.partnerTestimonialContent
														.image?.altText
												}
												badgeAlt={
													partner
														.partnerTestimonialContent
														.icon?.altText
												}
												bio={
													partner
														.partnerTestimonialContent
														.bio
												}
												year={`${partner.partnerTestimonialContent.followerCount} followers`}
												imageUrl={
													partner
														.partnerTestimonialContent
														.image?.sourceUrl
												}
												badgeUrl={
													partner
														.partnerTestimonialContent
														.icon?.sourceUrl
												}
												key={index}
											/>
										)
									)}
								</div>
							</article>
							<div className="flex justify-center items-center pt-10 gap-5 pb-12 md:pb-0 2xl:mb-[-2rem] flex-col md:flex-row">
								<div className="text-lg font-bold text-center lg:text-left">
									Want to be featured on the website and bring
									Classify to your school?
								</div>
								<ButtonLink to="/apply" anchor>
									Become a Classify Ambassador
								</ButtonLink>
							</div>
							<div className="text-center  pb-8 md:pb-16 mx-auto space-y-8">
								<div className="mb-8 md:mb-20">
									<span
										className="inline-block lg:hidden text-center font-tinyAngels text-5xl lg:text-8xl md:text-7xl font-black"
										style={{
											lineHeight: 1.25,
										}}
									>
										T|ME F0R L|FT 0FF 🚀
									</span>
								</div>
								<header
									className="lg:mx-auto lg:px-10"
									style={{
										maxWidth: '77ch',
										wordWrap: 'break-word',
									}}
								>
									<span className="font-bold text-2xl lg:text-4xl md:text-4xl">
										It's time to download the only
										organization app you'll ever need.
									</span>
								</header>
								<div className="flex justify-center">
									<img
										src={RightCallArrow}
										alt="Right call to action image"
										className="hidden lg:block"
										style={{ height: '6.5rem' }}
									/>
									<StoreEmbedButtons className="store-embed-footer" />
									<img
										src={LeftCallArrow}
										alt="Left call to action image"
										className="hidden lg:block"
										style={{ height: '7rem' }}
									/>
								</div>
								<div className="hidden md:block font-black text-[#062AFF] text-lg">
									<a href="/blog/updates">
										Read more about our latest update &#62;
									</a>
								</div>
							</div>
						</Container>
						<section className="hidden md:block mx-auto relative md:px-5 pb-20">
							<ReviewCards />
						</section>
						{/* 
						<section className="review-cards-mobile hidden mx-auto relative md:px-5 pb-9">
							<ReviewCardsMobile />
						</section> */}
					</section>
				</main>
			</Layout>
		</>
	)
}

export const pageQuery = graphql`
	query {
		allWpPartnersTestimonial(limit: 3) {
			nodes {
				title
				partnerTestimonialContent {
					bio
					followerCount
					icon {
						altText
						sourceUrl
					}
					image {
						altText
						sourceUrl
					}
				}
			}
		}
	}
`

export default IndexPage
