import React from 'react'
import GlobeIcon from '../images/globe_rating.png'
import StarRating from '../images/star-rating.png'
import CommunityImage from '../images/students_rating.png'

const data = [
	{
		title: '181 countries',
		subtext: 'worldwide',
		image: GlobeIcon,
		customStyle: { height: '50px', width: '50px', margin: 'auto' },
		mobileStyle: { height: '1.6rem', width: '1.6rem', margin: 'auto' },
	},
	{
		title: '4.6 stars',
		subtext: 'average rating',
		image: StarRating,
		mobileStyle: { margin: 'auto' },
	},
	{
		title: '120k students',
		subtext: 'across all platforms',
		image: CommunityImage,
		customStyle: { height: '50px', width: '50px', margin: 'auto' },
		mobileStyle: { height: '1.6rem', width: '1.6rem', margin: 'auto' },
	},
]

const dataMobile = [
	{
		title: '4.6 stars',
		subtext: 'average rating',
		image: StarRating,
		mobileStyle: {
			margin: 'auto',
			paddingBottom: '8px',
		},
	},
	{
		title: '181 countries',
		subtext: 'worldwide',
		image: GlobeIcon,
		customStyle: { height: '50px', width: '50px', margin: 'auto' },
		mobileStyle: { height: '1.6rem', width: '1.6rem', margin: 'auto' },
	},
	{
		title: '120k students',
		subtext: 'worldwide',
		image: CommunityImage,
		customStyle: { height: '50px', width: '50px', margin: 'auto' },
		mobileStyle: { height: '1.6rem', width: '1.6rem', margin: 'auto' },
	},
]

const ReviewCards = () => {
	return (
		<div className="margin-auto flex justify-center py-10 text-center place-items-center">
			{data.map((item, idx) => {
				return (
					<ReviewCard
						title={item.title}
						subtext={item.subtext}
						image={item.image}
						customStyle={item.customStyle}
						key={idx}
					/>
				)
			})}
		</div>
	)
}

export const ReviewCardsMobile = () => {
	return (
		<div style={{ maxWidth: '1536px' }}>
			<div className="flex justify-center">
				<ReviewCardMobile
					title={dataMobile[0].title}
					subtext={dataMobile[0].subtext}
					image={dataMobile[0].image}
					mobileStyle={dataMobile[0].mobileStyle}
				/>
			</div>
			<div className="flex pt-3 justify-center">
				{dataMobile.slice(1).map((item, idx) => {
					return (
						<ReviewCardMobile
							title={item.title}
							subtext={item.subtext}
							image={item.image}
							mobileStyle={item.mobileStyle}
							key={idx}
						/>
					)
				})}
			</div>
		</div>
	)
}

const ReviewCard = ({ title, subtext, image, customStyle }) => {
	return (
		<div className="rounded-3xl w-[19rem] h-[14rem] bg-navy py-10 px-10 mx-5">
			<img src={image} alt={`Title image ${title}`} style={customStyle} />
			<div className="pt-7 text-white">{title}</div>
			<div className="pt-3 text-[#7D83E9]">{subtext}</div>
		</div>
	)
}

const ReviewCardMobile = ({ title, subtext, image, mobileStyle }) => {
	return (
		<div className="rounded-3xl w-[10rem] h-[8rem] bg-navy py-8 px-10 mx-3 text-center">
			<img src={image} alt={`Title image ${title}`} style={mobileStyle} />
			<div className="pt-2 text-white text-[10px]">{title}</div>
			<div className="pt-2 text-[#7D83E9] text-[10px]">{subtext}</div>
		</div>
	)
}

export default ReviewCards
