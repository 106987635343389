import React from 'react'
import Container from '../components/Container'
import ButtonLink from '../components/ButtonLink'
import StoreEmbedButtons from './StoreEmbedButtons'
import HomeworkImage from '../images/homework.png'
import ZoneImage from '../images/zone.png'
import TimeTableImage from '../images/timetable.png'
import CalendarImage from '../images/calendar-icon.png'
import TheatreImage from '../images/theatre.png'
import SoccerImage from '../images/soccer.png'

function FeatureSection() {
	const data = {
		image: ZoneImage,
		title: 'Get in the Zone.',
		content:
			"Power through any homework or task effectively and distraction-free, then look back at the progress you've made. Now includes checklists.",
	}
	return (
		<>
			<TopFeature />
			<FeatureOne />
			<NewFeature data={data} />
			<FeatureTwo />
		</>
	)
}

function TopFeature() {
	return (
		<Container no-vertical>
			<div className="flex flex-col justify-center items-center pt-20 md:pt-32">
				<iframe
					className="feature-yt-embed"
					src="https://www.youtube.com/embed/MpoUCZy89UY?si=SaDcSoTZSIXrVcys&amp;rel=0&amp;modestbranding=1"
				></iframe>
				<div className="flex flex-col justify-center items-center text-center">
					<span className="flex items-center pt-10">
						<span
							className="text-4xl font-black "
							style={{
								wordBreak: 'break-word',
							}}
						>
							Many minds, one mission. 🏘️
						</span>
					</span>
					<p
						className="w-full pt-10"
						style={{
							maxWidth: '44ch',
							wordBreak: 'break-word',
						}}
					>
						Bringing social to the school planner. Discuss, share
						and collaborate on homework, events and clubs with your
						friends and classmates. Try it now by adding your
						classes, clubs and projects to Classroom!
					</p>
					<div className="pt-10">
						<ButtonLink to="/#hero" color="blue" anchor>
							Invite classmates now!
						</ButtonLink>
					</div>
				</div>
			</div>
		</Container>
	)
}

function FeatureOne() {
	return (
		<Container no-vertical>
			<div
				className="grid gap-12 lg:gap-32 lg:grid-cols-2 items-center relative mx-auto"
				style={{ maxWidth: '1000px' }}
			>
				<div className="grid gap-8 pb-0 md:px-24 lg:px-0 pt-10 lg:pt-24 h-full">
					<div
						className="lg:hidden lg:mx-48 w-full h-full"
						style={{
							paddingTop: '50%',
							backgroundImage: `url(${HomeworkImage})`,
							backgroundPosition: 'top',
							backgroundSize: '100% auto',
							backgroundRepeat: 'no-repeat',
						}}
					/>
					<span className="flex items-center">
						<span
							className="text-4xl font-black"
							style={{
								maxWidth: '14ch',
								wordBreak: 'break-word',
							}}
						>
							Write it down. Level up. 🚀
						</span>
					</span>
					<p className="w-full">
						Jot down, edit and delete homework in seconds. Staying
						on top of assignments, tests and exams no longer
						requires effort.
					</p>
					<div>
						<ButtonLink to="/#hero" color="orange" anchor>
							Start adding homework now!
						</ButtonLink>
					</div>
				</div>

				<div className="hidden lg:block relative h-full">
					<div
						className="absolute bottom-0 right-0 w-full h-full"
						style={{
							top: '30%',
							background: `url(${HomeworkImage})`,
							backgroundPosition: 'top',
							backgroundSize: '100% auto',
							backgroundRepeat: 'no-repeat',
						}}
					></div>
				</div>
			</div>
		</Container>
	)
}

function NewFeature({ data }) {
	return (
		<Container no-vertical>
			<div
				className="grid gap-12 lg:gap-32 lg:grid-cols-2 items-center relative mx-auto"
				style={{ maxWidth: '1000px' }}
			>
				<div className="hidden lg:block relative h-full">
					<div
						className="absolute bottom-0 right-0 w-full h-full"
						style={{
							top: '25%',
							background: `url(${data.image})`,
							backgroundPosition: 'top',
							backgroundSize: '100% auto',
							backgroundRepeat: 'no-repeat',
						}}
					></div>
				</div>

				<div className="grid gap-3 pb-0 md:px-24 lg:px-0 pt-12 md:pt-24 lg:pt-30">
					<div
						className="lg:hidden lg:mx-48"
						style={{
							paddingTop: '60%',
							backgroundImage: `url(${data.image})`,
							backgroundPosition: 'top',
							backgroundSize: '100% auto',
							backgroundRepeat: 'no-repeat',
							borderRadius: '25px',
						}}
					/>
					{/* <div className="flex w-[15%] justify-center items-center mt-2  py-2 px-2 bg-[#305FFF] rounded-full text-white border">
						<div className="text-xs font-black font-normal leading-none flex-initial">
							NEW
						</div>
					</div> */}
					<span className="flex items-center">
						<span className="text-4xl font-black">
							{data.title}
						</span>
					</span>
					<p className="w-full">{data.content}</p>
					<div>
						<StoreEmbedButtons className="store-embed-new-feature" />
					</div>
				</div>
			</div>
		</Container>
	)
}

function FeatureTwo() {
	const listItems = [
		{
			title: 'Timetable',
			content:
				'Supports alternating timetables and automatic reminders so you never miss a thing.',
			image: CalendarImage,
		},
		{
			title: 'Events',
			content:
				'Get reminders about upcoming events, such as meetings, trips or exams.',
			image: TheatreImage,
		},
		{
			title: 'Clubs',
			content:
				'Explore any passions or interests, keeping track of your regular meets or events.',
			image: SoccerImage,
		},
	]
	return (
		<Container no-vertical>
			<div
				className="grid gap-12 lg:gap-32 lg:grid-cols-2 items-center relative mx-auto"
				style={{ maxWidth: '1000px' }}
			>
				<div className="grid gap-8 pb-0 md:px-24 lg:px-0 pt-5 md:pt-24 lg:pt-36">
					<div
						className="lg:hidden lg:mx-48 w-full h-full"
						style={{
							paddingTop: '100%',
							backgroundImage: `url(${TimeTableImage})`,
							backgroundPosition: 'top',
							backgroundSize: '100% auto',
							backgroundRepeat: 'no-repeat',
							borderRadius: '25px',
						}}
					/>
					<span className="flex items-center">
						<span className="text-4xl font-black">
							Stay ahead, always.
						</span>
					</span>
					<div className="w-full">
						<p>
							Whatever's coming up, whether it's your events,
							clubs, or lessons, we've got you covered.
						</p>
						<ul className="pt-5">
							{listItems.map((listItem, idx) => {
								return (
									<li className="py-2" key={idx}>
										<div className="flex items-center">
											<img
												src={listItem.image}
												style={{
													height: '3.38rem',
													width: '3.38rem',
												}}
												className="mr-10 mb-8"
											/>
											<div>
												<span className="font-bold text-xl">
													{listItem.title}
												</span>
												<p>{listItem.content}</p>
											</div>
										</div>
									</li>
								)
							})}
						</ul>
					</div>
					<div>
						<ButtonLink to="/#hero" color="green" anchor>
							Set up your timetable now!
						</ButtonLink>
					</div>
				</div>

				<div className="hidden lg:block relative h-full">
					<div
						className="absolute bottom-0 right-0 w-full h-full"
						style={{
							top: '19%',
							background: `url(${TimeTableImage})`,
							backgroundPosition: 'top',
							backgroundSize: '90% auto',
							maxWidth: '390px',
							backgroundRepeat: 'no-repeat',
						}}
					></div>
				</div>
			</div>
		</Container>
	)
}

export default FeatureSection
