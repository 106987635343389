import React, { useEffect, useRef, useState } from 'react'
//import Hammer from 'hammerjs'
import '../styles/carousel.scss'
import RightArrow from '../images/right_arrow_icon.png'
import LeftArrow from '../images/left_arrow_icon.png'

function Carousel({ data }) {
	const [index, setIndex] = useState(1)
	const length = data.length

	const container = useRef(null)
	const prevBtn = useRef(null)
	const nextBtn = useRef(null)

	useEffect(() => {
		const children = container.current.children
		const current = children[index]
		const next = children[(index + 1) % length]
		const prev = children[index === 0 ? length - 1 : index - 1]
		const clearStyles = () => {
			for (let i = 0; i < children.length; i++) {
				children[i].classList.remove('current')
				children[i].classList.remove('prev')
				children[i].classList.remove('next')
			}
		}

		current.classList.add('current')
		prev.classList.add('prev')
		next.classList.add('next')

		const timeout = window.setTimeout(() => {
			clearStyles()
			setIndex((index + 1) % length)
		}, 5000)

		const increment = () => {
			window.clearTimeout(timeout)
			clearStyles()
			setIndex((index + 1) % length)
		}
		const decrement = () => {
			window.clearTimeout(timeout)
			clearStyles()
			setIndex(index === 0 ? length - 1 : index - 1)
		}

		current.onclick = null
		nextBtn.current.onclick = () => increment()
		next.onclick = () => increment()
		prevBtn.current.onclick = () => decrement()
		prev.onclick = () => decrement()

		// let hammer
		// if (typeof window !== 'undefined') {
		// 	import('hammerjs').then(({ default: Hammer }) => {
		// 		hammer = new Hammer(container.current)
		// 		hammer
		// 			.get('swipe')
		// 			.set({ direction: Hammer.DIRECTION_HORIZONTAL })
		// 		hammer.on('swipe', (e) =>
		// 			e.direction === 2 ? increment() : decrement()
		// 		)
		// 	})
		// }

		return () => {
			window.clearTimeout(timeout)
			// hammer?.destroy()
		}
	})

	return (
		<div className="w-full overflow-x-hidden">
			<div className="h-48 lg:h-72 my-16 w-full">
				<div
					className="relative h-full w-full overflow-x-hidden"
					ref={container}
				>
					{data.map((d, i) => {
						return (
							<img
								src={d.image}
								alt={`Carousel section ${i}`}
								width="1369"
								height="1029"
								className="w-auto h-full left-0 right-0 absolute mx-auto z-0 opacity-0"
								style={{ transition: 'transform 0.5s' }}
								key={i}
							></img>
						)
					})}
				</div>
			</div>
			<div className="flex text-center mx-auto justify-center items-center overflow-x-hidden">
				<button
					ref={prevBtn}
					style={{
						background: `url(${LeftArrow})`,
						backgroundPosition: 'top',
						backgroundSize: '100% auto',
						maxWidth: '390px',
						top: '30%',
						height: '80px',
						width: '80px',
					}}
					className="grow hidden lg:block"
				/>
				<div className="bg-[#FFD1CE] h-[10rem] w-[25rem] mx-7 md:mx-0 rounded-3xl grow overflow-x-hidden">
					<div className="flex justify-center items-center py-7">
						<img
							src={data[index].icon || ''}
							alt={data[index].title}
							style={{
								height: '40px',
								width: '40px',
								marginRight: '10px',
							}}
						/>
						<span className="text-3xl text-black font-bold">
							{data[index].title}
						</span>
					</div>

					<p className="text-black-alt">{data[index].body}</p>
					<a
						href="/blog/updates"
						className="button-link bg-blue"
						rel="noopener noreferrer"
					>
						LEARN MORE
					</a>
				</div>
				<button
					ref={nextBtn}
					style={{
						background: `url(${RightArrow})`,
						backgroundPosition: 'top',
						backgroundSize: '100% auto',
						maxWidth: '390px',
						top: '30%',
						height: '80px',
						width: '80px',
					}}
					className="grow hidden lg:block"
				/>
			</div>
		</div>
	)
}

export default Carousel
