import {
	CarouselProvider,
	Slider,
	Slide,
	ButtonBack,
	ButtonNext,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import RightArrow from '../images/right_arrow_icon.png'
import LeftArrow from '../images/left_arrow_icon.png'
import useWindowWidth from '../hooks/useWindowWidth'

function TestimonialCarousel() {
	const pageQuery = graphql`
		query {
			allWpTestimonial {
				nodes {
					testimonialContent {
						review
						occupation
					}
					title
				}
			}
		}
	`
	const data = useStaticQuery(pageQuery)
	const dataLength = data.allWpTestimonial.nodes.length
	const windowWidth = useWindowWidth()

	const slidesPerView = windowWidth <= 1536 ? 5 : 7
	return (
		<>
			<CarouselProvider
				naturalSlideWidth={100}
				naturalSlideHeight={50}
				totalSlides={dataLength}
				visibleSlides={dataLength < 5 ? dataLength : slidesPerView}
				infinite
			>
				<Slider className="lg:pb-[8rem]">
					{data.allWpTestimonial.nodes.map((testimonial, idx) => {
						return (
							<Slide index={idx} key={idx}>
								<TestimonialCard
									reviewContent={
										testimonial.testimonialContent.review
									}
									reviewerName={testimonial.title}
									reviewerOccupation={
										testimonial.testimonialContent
											.occupation
									}
								/>
							</Slide>
						)
					})}
				</Slider>
			</CarouselProvider>
		</>
	)
}

const TestimonialCard = ({
	reviewContent,
	reviewerName,
	reviewerOccupation,
}) => {
	return (
		<article className="rounded-3xl bg-navy py-10 px-10 mx-5 text-white h-[15rem] flex flex-col">
			<div>{reviewContent}</div>
			<div className="flex flex-col justify-end h-full">
				<div>{reviewerName}</div>
				<div className="text-[#7D83E9]">{reviewerOccupation}</div>
			</div>
		</article>
	)
}

export default TestimonialCarousel
